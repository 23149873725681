<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";

import {mapState} from "vuex";

export default {
  page: {
    title: "Ban Manager",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    BanmanagerList: () => import('@/components/lazy/banmanager/list/BanmanagerList'),
  },
  validations: {

  },
  methods: {

  },
  created() {

  },
  data() {
    return {

    }
  }
};
</script>

<!--
<template v-if="invoices.length === 0">
        <div class="col-lg justify-content-center">
          <div  class="info-component text-center align-middle mt-auto mb-auto">
            <div class="info-component-space">
              <i class="fad fa-empty-set info-component-icon"></i>
            </div>
            <div class="row" style="margin-top: 20px;">
              <div class="col-lg-12">
                <h3 v-b-tooltip.hover title="Wow such empty 👻">{{$t('invoices.empty.title')}}</h3>
                <h5 class="text-muted">{{$t('invoices.empty.message')}}</h5>
              </div>
            </div>
          </div>
        </div>
      </template>
      -->

<template>
  <Layout>
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <h4 class="mb-0 font-size-18">
        Ban manager
      </h4>
      <div class="page-title-right bv-d-xs-down-none">
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item">
            <router-link to="/">
              CFTools Cloud
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <span aria-current="location">
              Ban manager
            </span>
          </li>
        </ol>
      </div>
    </div>
    <BanmanagerList/>
  </Layout>
</template>